$base-font-size: 1vw;
// $base-font-size: 16px;

// Headings
$heading-color: #0085AD;
$sub-heading-color: #7D023B;
$heading-font-size: 1.55rem;
$heading-margin: 0 0 2rem 0;
$heading-line-height: 1.425em;

// Body text
$text-color: #3A4648;
$text-paragraph-margin: 0 0 1rem 0;
$text-line-height: 1.5em;


// Buttons
$button-padding-horizontal: 30px;
$button-color: #fff;
$button-background: #0085AB;
$button-line-height: 2.25rem;
$button-border-radius: 1.125rem;
$button-font-size: 1.2rem;

$button-red-background: #7D023B;
$button-green-background: #7db0af;
$button-white-background: #ffffff;

// Colours
$color-designing_buildings: #72163b;
$color-designing_features: #817269;
$color-constructing: #7cb0af;
$color-installing: #0a3f67;
$color-sustainability: #02594d;
$color-projects: #a0b35a;
$color-cultural: #f08000;
$color-warehouse: #0083a8;
$color-apprentice: #0083a8;
$color-help: #0083a8;
$dark-grey: #717572;
$medium-grey: #8D918D;
$light-grey: #e5e5e5;

$stripe-pattern: repeating-linear-gradient(
  135deg,
  $dark-grey,
  $dark-grey 2px,
  $medium-grey 2px,
  $medium-grey 20px
);

// Start
$start-background: #E6E6DB;

//Welcome
$sky-background-welcome: linear-gradient(#9cbdd5, #c9dce3);
$sky-background: #9cbdd5;


//Hotspot content
$intro-red: #7D023B;

// Menu
$menu-dark-grey: #38474f;
$menu-medium-grey: #4c5960;
$menu-light-grey: #606c72;


//Media queries
$sm: 375px;
$md: 768px;
$lg: 992px;
$xl: 1200px;


//Class to set guide specific color

$guide-list: (
  'designing_buildings': $color-designing_buildings,
  'designing_features': $color-designing_features,
  'constructing': $color-constructing,
  'installing': $color-installing,
  'sustainability': $color-sustainability,
  'projects': $color-projects,
  'warehouse': $color-warehouse,
  'cultural': $color-cultural,
  'apprentice': $color-apprentice
);

@mixin guide-color($guide) {
  &__#{$guide} {
    color: map-get($guide-list, $guide) !important;
    p.Hotspot__title,
    h1.Hotspot__intro {
      color: map-get($guide-list, $guide);
    }
    &--border-color {
      border-color: map-get($guide-list, $guide);
    }

    &--background {
      background: map-get($guide-list, $guide);
      &:hover {
        background: darken(map-get($guide-list, $guide), 5%);
      }
    }
    &--background-no-hover {
      background: map-get($guide-list, $guide);
    }
    &--fill {
      svg .cls-1 {
        fill: map-get($guide-list, $guide);
      }

      .cls-1 {
        fill: map-get($guide-list, $guide);
      }
    }
    &--outline {
      outline: 10px solid map-get($guide-list, $guide);
    }
  }
}
.Color {
  @include guide-color('designing_buildings');
  @include guide-color('designing_buildings');
  @include guide-color('designing_features');
  @include guide-color('constructing');
  @include guide-color('installing');
  @include guide-color('sustainability');
  @include guide-color('projects');
  @include guide-color('warehouse');
  @include guide-color('cultural');
  @include guide-color('apprentice');
}