@import "../_variables";


@keyframes green-dot-pulsate {
  from {
    r: 0.9;
  }

  to {
    r: 0.4;
  }
}


@keyframes large-map-green-dot-pulsate {
  from {
    r: 4;
  }

  to {
    r: 3;
  }
}

@mixin room-history($rooms-list) {
  @each $var in $rooms-list {
    .HistoryMap--#{$var} {
      .map_#{$var} {
        .green_dot,
        .green_dot_light {
          display: block;
          animation-duration: 0.5s;
          animation-name: green-dot-pulsate;
          animation-iteration-count: infinite;
          animation-direction: alternate;
        }
      }
      g#Gatehouse_Inside {
        .map_#{$var} {
          .green_dot,
          .green_dot_light {
            animation-name: large-map-green-dot-pulsate;
          }
        }
      }
    }

    .HistoryMap_visited--#{$var} {
      .map_#{$var} {
        .light-grey {
          fill: #8D918D;
        }

        .light-grey-large-map {
          fill: #8D918D;
        }
      }
    }
  }
}


.RoomButton {
  &-ambassadors_office { left: 52.1%; top: 64%; }
  &-office             { left: 52.1%; top: 74.5%; }
  &-reception          { left: 52.1%; top: 87%; }
  &-exterior_front     { left: 44%; top: 89%; }
  &-gatehouse          { left: 35.8%; top: 89%; }
  &-server_room        { left: 67.3%; top: 64%; }
  &-kitchen            { left: 67.3%; top: 74.5%; }
  &-meeting_room       { left: 67.3%; top: 87%; }
  &-exterior_back      { left: 82%; top: 82%; }
  &-roof               { left: 59.65%; top: 53.5%; }
  &-lift               { left: 59.65%; top: 79%; }
  &-garage             { left: 52%; top: 97%; }
  &-basement           { left: 67.3%; top: 97%; }
  &-perimeter          { left: 22.5%; top: 87%; }
}

html {
  background-color: $sky-background;
}

body {
  overflow: hidden;
}

.Embassy {
  width: 100vw;
  height: 100vh;

  &__scrollButton {
    border-color: transparent;
    background: transparent;
    padding: 0;
    width: 4%;
    max-height: 100px;
    position: fixed;
    top: 47%;
    z-index: 2;
    cursor: pointer;

    &__right {
      right: 2%;
    }

    &__left {
      left: 2%;
    }

    img {
      height: 100%;
    }
  }

  &__content {
    background: $start-background;

    /* CSS Transition stuff */
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transition: width 1.5s;
    /* End CSS Transition stuff */
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: 0;

    &__preview {
      width: 100vw;
    }

    &__full {
      width: 160vw;
    }

    &__scene {
      width: 100%;
      display: block;
      transform-origin: center center;
      transition: transform 500ms ease-in;
    }
  }

  &__Room__Overlay {
    position: fixed;
    display: flex;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: 0;

    padding: 15%;

    z-index: 5;

    background: $stripe-pattern;
  }

  &__Room__Container-outer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 10;
    .Embassy__Room__Image {
      display: block;
      @media (max-aspect-ratio: 197/100) {
        width: 100%;
      }
      @media (min-aspect-ratio: 197/100) {
        width: 100%;
      }
    }
  }

  &__Room__Container-inner {
    position: relative;
  }

  &__Room__Controls {
    &__Left, &__Right, &__Up, &__Down {
      position: absolute;
      border-color: transparent;
      padding: 0;
      background: transparent;
      cursor: pointer;

      width: 5%;
      img {
        width: 100%;
      }
    }

    &__Left {
      left: -6%;
      top: 50%;
      transform: rotate(90deg);
    }

    &__Right {
      right: -6%;
      top: 50%;
      transform: rotate(270deg);
    }

    &__Up {
      top: -12%;
      left: 50%;
      transform: rotate(180deg);
    }

    &__Down {
      bottom: -12%;
      left: 50%;
    }
  }

  .RoomsHistory {
    cursor: pointer;
    position: absolute;
    width: 20.5%;
    left: 0;
    bottom: -10.7%;

    .green_dot,
    .green_dot_light {
      display: none;
    }

    @include room-history('ambassadors_office' 'basement' 'exterior_back' 'exterior_front' 'garage' 'gatehouse' 'kitchen' 'lift' 'meeting_room' 'office' 'perimeter' 'reception' 'roof' 'server_room')
  }
}

.EmbassyPreview {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #9DBDD6;
  z-index: 3;

  &__Image {
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 52.3vw;

    background-image: url(/assets/embassy-exterior.jpg);
    background-position: bottom left;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

