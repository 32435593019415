.RoomButton,
.HotspotButton {
  position: absolute;
  -webkit-appearance: none;
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
  @media (min-width: $md) {
    width: 30px;
    height: 30px;
  }

  @media (min-width: $xl) {
    width: 40px;
    height: 40px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  &-pulsating {
    animation-duration: 1s; 
    animation-name: room-button-pulsate; 
    animation-iteration-count: infinite; 
    animation-direction: alternate;
  }

  &__embassy-specific {
    z-index:3;
    transform: translateX(-50%) translateY(-50%);    
  }
}

.HotspotButton {
  transform-origin: center center;
  transform: translate3d(-50%, -50%, 0);
  overflow: visible;

  &--ShowTooltip {
    z-index: 3;
    &__top {
      &:before {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        clear: both;
        left: 30%;
        top: -65%;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid $dark-grey;
        @media (min-width: $md) {
          top: -45%;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid $dark-grey;
        }

        @media (min-width: $xl) {
          top: -35%;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid $dark-grey;
        }
      }
    }

    &__bottom {
      &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        clear: both;
        left: 25%;
        top: 90%;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid $dark-grey;;
        @media (min-width: $md) {
          top: 100%;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid $dark-grey;;
        }

        @media (min-width: $xl) {
          top: 110%;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid $dark-grey;;
        }
      }
    }

  }

}

.TooltipText {
  position: absolute;
  font-size: 100%;
  padding: 5px;
  color: white;
  background-color: $dark-grey;
  border-radius: 10px;
  right: -10%;
  margin: auto;
  white-space: nowrap;
  min-width: 100%;
  &--right {
    right: inherit;
 }
  &--top {
    top: -35px;
  }
  &--bottom {
    bottom: -140%;
    @media (min-width: $md) {
      bottom: -120%;
    }
    @media (min-width: $xl) {
      bottom: -90%;
    }
  }
}

.HotspotButton#HotspotButton-cultural svg .cls-1 { fill: $color-cultural; }

@keyframes room-button-pulsate {
  from {
    opacity: 1;
    transform: scale(1.5);
  }

  to {
    opacity: 0.4;
    transform: scale(1);
  }
}