.Carousel {
  max-width: 90vw;
  text-align: center;

  &__item {
    cursor: pointer;
    box-sizing: border-box;
    padding: 0 10px;
    @media (min-width: $lg) {
      padding: 20px;
    }
    font-size: 0.875rem;
    transition: all 300ms ease-in;

    img {
      margin-bottom: 1rem;
    }
  }

  &__arrow {
    position: absolute;
    width: 6%;
    top: 40%;
    transform: translateY(-50%);
    cursor: pointer;
    -webkit-appearance: none;
    background: none;
    border: 0;
    padding: 0;
    z-index: 3;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    &--left {
      left: -2%;

      /* Apple iPhone XR 1792x828px at 326ppi Media Queries */
      @media only screen
      and (device-width : 414px)
      and (device-height : 896px)
      and (-webkit-device-pixel-ratio : 2) {
        left: 0;
      }

      /* Apple iPhone XS 2436x1125px at 458ppi Media Queries */
      @media only screen
      and (device-width : 375px)
      and (device-height : 812px)
      and (-webkit-device-pixel-ratio : 3) {
        left: 0;
      }

      /* Apple iPhone XS Max 2688x1242px at 458ppi Media Queries */
      @media only screen
      and (device-width : 414px)
      and (device-height : 896px)
      and (-webkit-device-pixel-ratio : 3) {
        left: 0;
      }

      /* Apple iPhone X Media Queries */
      @media only screen
      and (device-width : 375px)
      and (device-height : 812px)
      and (-webkit-device-pixel-ratio : 3) {
        left: 0;
      }
    }

    &--right {
      right: -2%;
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .slick-list {
    width: 85%;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: $lg) {
      padding-top: 40px;
    }
  }

  .slick-slide {
    width: 12.5%!important;
  }

  .slick-slider .slick-arrow {
    &:before {
      content: '';
    }
  }
}
