@import "../_variables";

.HotspotContent {
  position: absolute;
  top: 10%;
  left: 6%;
  width: 88%;
  bottom: 0;
  background: white;
  z-index: 4;


  &__Container {
    position: relative;
    height: 100%;
    font-size: 1.5vw;
    box-shadow: 0 0 10px $medium-grey;
    .Tabs {
      display: flex;
    }
  }


  .VoiceOver__close {
    cursor: pointer;
    width: 4%;
    top: -2rem;
    right: -2rem;

    img {
      max-height: 40px; //added to fix height issue in IE11
    }
  }

  &__Slides__Container {
    padding: 4% 10%;
  }

  &__Overview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 80%;
    padding: 0 15%;
  }

  h1.Hotspot__intro {
    font-size: 100%;
    line-height: 1.525em;
    margin: 0;
  }

  .HotspotContent__Title {
    p {
      font-size: 100%;
      line-height: 1.525em;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 600;
    }
  }

  .HotspotContent__Slides__Container ul li {
    margin: 0 0 0.5rem 0;

    font-size: 80%;
    @media (min-width: $md) {
      font-size: 70%;
    }
    /* Apple iPhone XR 1792x828px at 326ppi Media Queries */
    @media only screen
    and (device-width : 414px)
    and (device-height : 896px)
    and (-webkit-device-pixel-ratio : 2) {
      font-size: 42%;
    }

    /* Apple iPhone XS 2436x1125px at 458ppi Media Queries */
    @media only screen
    and (device-width : 375px)
    and (device-height : 812px)
    and (-webkit-device-pixel-ratio : 3) {
      font-size: 42%;
    }

    /* Apple iPhone XS Max 2688x1242px at 458ppi Media Queries */
    @media only screen
    and (device-width : 414px)
    and (device-height : 896px)
    and (-webkit-device-pixel-ratio : 3) {
      font-size: 42%;
    }

    /* Apple iPhone X Media Queries */
    @media only screen
    and (device-width : 375px)
    and (device-height : 812px)
    and (-webkit-device-pixel-ratio : 3) {
      font-size: 42%;
    }
  }

  .HotspotContent__Paragraphs p.Hotspot__paragraph {
    font-size: 60%;
    @media (min-width: $md) {
      font-size: 70%;
    }
    /* Apple iPhone XR 1792x828px at 326ppi Media Queries */
    @media only screen
    and (device-width : 414px)
    and (device-height : 896px)
    and (-webkit-device-pixel-ratio : 2) {
      font-size: 40%;
    }

    /* Apple iPhone XS 2436x1125px at 458ppi Media Queries */
    @media only screen
    and (device-width : 375px)
    and (device-height : 812px)
    and (-webkit-device-pixel-ratio : 3) {
      font-size: 42%;
    }

    /* Apple iPhone XS Max 2688x1242px at 458ppi Media Queries */
    @media only screen
    and (device-width : 414px)
    and (device-height : 896px)
    and (-webkit-device-pixel-ratio : 3) {
      font-size: 42%;
    }

    /* Apple iPhone X Media Queries */
    @media only screen
    and (device-width : 375px)
    and (device-height : 812px)
    and (-webkit-device-pixel-ratio : 3) {
      font-size: 42%;
    }
  }

  .HotspotContent__Paragraphs {
    overflow: hidden;
  }

  .Overview__tab,
  .Happening__tab,
  .Involved__tab {
    margin: 0;
    background: $light-grey;
    border: none;
    padding: 0;
    padding-top: 1rem;
    font-size: 80%;
    font-weight: 600;
    flex-grow: 1;
    box-shadow: inset -0.1em -0.1em 0.3em -0.1em $medium-grey;
    cursor: pointer;
    outline-offset: -3px;

    &--active {
      background: white;
      box-shadow: none;
    }
  }

  .Overview__tab--active p {
    color: $text-color;
  }  

  .HotspotContent__Nav {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding-bottom: 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Button--Prev img,
  .Button--Next img {
    cursor: pointer;
    height: 1.5vw;
    display: block;
    margin: 0 5px;
  }

  .Button--Prev {
    transform: rotate(180deg)
  }

  .ProgressDot {
    cursor: pointer;
    width: 1vw;
    height: 1vw;
    border-radius: 100%;
    background-color: $menu-dark-grey;
    margin: 0 5px;

    &--inactive {
      background-color: white;
      border: 2px solid $menu-dark-grey;
    }
  }

  .Involved__warehouse__pallet {
    li {
      font-size: 60%;
    }
  }

  &__Cultural {
    &--Icon {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 35%;
      padding: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    &--Icon.Color__cultural--background-no-hover svg {
      .cls-1 { fill: white; }
      .cls-2 { fill: $color-cultural; }
    }

    &--Slide {
      position: absolute;
      top: 0;
      left: 35%;
      bottom: 0;
      right: 0;
      padding: 4% 10%;

      p {
        font-size: 1.2vw;
      }
    }
  }

  &__Help {
    z-index: 6;

    &--Icon {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 35%;
      padding: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    &--Icon.Color__help--background-no-hover svg {
      .cls-1 { fill: white; }
      .cls-2 { fill: $color-help; }
    }

    &--Slide {
      position: absolute;
      top: 0;
      left: 35%;
      bottom: 0;
      right: 0;
      padding: 5% 10%;

      p {
        font-size: 1.2vw;
      }
    }
  }
}

