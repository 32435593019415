.RoomsHistoryMap {
  @include room-history('ambassadors_office' 'basement' 'exterior_back' 'exterior_front' 'garage' 'gatehouse' 'kitchen' 'lift' 'meeting_room' 'office' 'perimeter' 'reception' 'roof' 'server_room')

  &--intro {
    position: absolute;
    top: 12%;
    left: 7%;
    width: 30%;

    p {
      font-size: 60%;
      @media (min-width: $md) {
        font-size: 87%;
      }
    }
  }
  .RoomsHistoryMapLargeContainer {
    width: 100%;
    height: 0;
    padding-top: 51.5%;
    position: relative;
  
    svg {
      position: absolute;
      top: 4%;
      left: 0;

      #ambassadors_office,
      #basement,
      #exterior_back,
      #exterior_front,
      #garage,
      #gatehouse,
      #kitchen,
      #lift,
      #meeting_room,
      #office,
      #perimeter,
      #reception,
      #roof,
      #server_room {
        cursor: pointer;
      }
    }
  }

  .green_dot,
  .green_dot_light {
    display: none;
  }
  position: absolute;
  top: 10%;
  left: 6%;
  width: 86%;
  background-color: white;
  z-index: 4;
  box-shadow: 0 0 10px $medium-grey;
}

.RoomsHistoryMap__close {
  cursor: pointer;
  width: 4%;
  top: -2rem;
  right: -2rem;
  position: absolute;
  border-color: transparent;
  padding: 0;
  background: transparent;
  img {
    max-height: 40px; //added to fix height issue in IE11
  }
}