@import "../_variables";

.Content {
  h1 {
    color: $heading-color;
    font-size: 0.9 * $heading-font-size;
    @media (min-width: $md) {
      font-size: $heading-font-size;
    }    
    margin-bottom: 1rem;
    @media (min-width: $md) {
      margin: $heading-margin;
    }    
    line-height: $heading-line-height;
    font-weight: normal;
  }

  p, li {
    color: $text-color;
    margin: $text-paragraph-margin;
    line-height: $text-line-height;
    font-size: 90%;
    @media (min-width: $md) {
      font-size: 100%;
    }
  }

  li {
    line-height: 0.7 * $text-line-height;
    @media (min-width: $md) {
      line-height: $text-line-height;
    }
  }
  ul {
    list-style-type: disc;
    margin: 0 0 0 1em;
  }

  p.Hotspot__list {
    margin-top: 2rem;
  }

  .Button {
    margin-top: 1vh;
    margin-bottom: 4vh;
    @media (min-width: $md) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}
