@import "../_variables";

.Welcome {
  background: $sky-background-welcome;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;

  &__background {
    img.Welcome__background-scene {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: auto;
      z-index: 5;
    }
    .Welcome__truck {
      position: absolute;
    /* IE10+ specific styles */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      bottom: 2vh;
    }
      bottom: 4vw;
      width: 30vw;
      margin-left:-60vw;
      z-index: 10;
    }
  }

  &__content {
    position: fixed;
    width: 70vw;
    z-index: 15;
    padding-left: 5vw;
    padding-bottom: 15vh;

    @media (min-width: $md) {
      width: 40vw;
    }

    /* IE10+ specific styles */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      top: 50%;
      transform: translateY(-50%);
    }

    img.Welcome__logo {
      display: block;
      margin-bottom: 4vh;
      height: auto;
      max-width: 55%;
      @media (min-width: $md) {
        max-width: 75%;
      }
    }
  }

  .Cloud__One {
    position: absolute;
    width: 30vw;
    bottom: 70vh;
    left: 30vw;
  }


  .Cloud__Two {
    position: absolute;
    width: 15vw;
    bottom: 60vh;
    left: -15vw;
  }

  .Cloud__Three {
    position: absolute;
    width: 20vw;
    bottom: 30vh;
    left: -30vw;
  }

  .Cloud__Four {
    position: absolute;
    width: 15vw;
    bottom: 10vh;
    left: 15vw;
  }
}
