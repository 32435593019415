$colors: (
  1: $menu-dark-grey,
  2: $menu-medium-grey,
  3: $menu-light-grey,
  4: lighten($menu-light-grey, 10%) //Used for hover color over items in last panel
);

@mixin menu($depth) {
  position: fixed;
  top: 0;
  width: calc(100vw/3);
  left: calc((100vw/3) * #{3 - $depth});
  bottom: 0;
  z-index: #{999 - $depth};
  background: map-get($colors, $depth);
  //hide scrollbar on windows chrome
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;

  padding-top: 5vh;
  @media only screen and (min-device-width: 414px) {
    padding-top: 15vh;
  }

  font-size: 1.5 * $base-font-size;
  color: #FFFFFF;
  &-enter {
    transform: translateX(100%) translateZ(0);
    &-active {
      transform: translateX(0) translateZ(0);
      transition: transform 500ms ease-in;
    }
  }

  &-leave {
    transform: translateX(0) translateZ(0);
    &-active {
      transform: translateX(100%) translateZ(0);
      transition: transform 500ms ease-in;
    }
  }


  li {
    padding-left: 5vw;
    cursor: pointer;
    &:hover {
      background: map-get($colors, $depth + 1);
    }
    img {
      margin-right: 5%;
      display: inline-block;
      vertical-align: middle;
      flex-shrink: 0;
      width: auto;
      height: 30px;

      @media (min-width: 992px) {
        width: auto;
        height: 50px;
      }
    }

    a {
      text-decoration: none;
      color: #FFFFFF;
    }
  }
}

.menu-0 {
  @include menu(1);
  li {
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
    margin-bottom: 2vh;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  #menu-footer {
    padding-left: 5vw;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      svg {
        height: 5vw;
      }
    }
  }
}

.menu-1 {
  &:before {
    box-shadow: -10px 0px 5px -5px map-get($colors, 1) inset;
    content: "";
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 2;
    width: 10px;
}
  @include menu(2);
  text-align: right;
  li {
    padding: 2.5vh 5vw 2.5vh 0;
  }
}

.menu-2 {
  &:before {
    box-shadow: -10px 0px 5px -5px map-get($colors, 2) inset;
    content: "";
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 2;
    width: 10px;
  }
  @include menu(3);
  justify-content: normal;
  text-align: right;
  overflow: scroll;
  li {
    padding: 2.5vh 5vw 2.5vh 0;
  }
}

.toggle-menu {
  z-index: 2;

  &--embassy {
    position: fixed;
    bottom: 3%;
    right: 2%;
    width: 4%;
  }
  &--room {
    position: absolute;
    bottom: -6.5%;
    right: -3.5%;
    width: 7%;
  }
}

.close-menu {
  z-index: 999;
  position: absolute;
  top: 5%;
  right: 10%;
  width: 8%;
  cursor: pointer;
}

.Menu--Logo {
  position: absolute;
  bottom: 7%;
  left: 5vw;
  width: 55%;
}

.ActiveMenuItem__0 {
  background: map-get($colors, 2);
}

.ActiveMenuItem__1 {
  background: map-get($colors, 3);
}