@import "../_variables";

.Start {
  background: $start-background;
  width: 100vw;
  min-height: 100vh;
  //Adding height to fix IE11 bug where setting only min-height breaks align-items: center; min-height will overwrite it.
  height: 100vh;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 2vh;
  @media (min-width: $lg) {
    padding: 3vw;
  }
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  &__pick, &__meet {
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .Content {
      width: 100%;
      h1 {
        text-align: center;
      }
    }
  }


  &__meet {
    font-size: 65%;
    @media (min-width: $lg) {
      font-size: 100%;
    }
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: flex-start;

    .GuideSummary {
      width: 25%;
    }

    .Content {
      width: 70%;
      margin-left: 5%;
    }
  }

  &__pick {
    .KeepGuide__Button {
      @media (min-width: $lg) {
        padding: 5px 20px;
        line-height: 1.7rem;
      }
      margin-left: -5%;
    }
  }
}

.slide-up-enter {
  transform: translateY(100vh);
  opacity: 0.01;
}

.slide-up-enter-active {
  transform: translateY(0);
  opacity: 1;
}
