.VoiceOver__close {
  cursor: pointer;
  position: absolute;
  border-color: transparent;
  padding: 0;
  background: transparent;
  img {
    max-height: 40px; //added to fix height issue in IE11
  }

}

.VoiceOver {
  &__embassy {
    .VoiceOver__image {
      position: fixed;
      top: 2vw;
      left: 2vw;
      width: 10vw;
      z-index: 3;
      img {
        width: 100%;
      }

      /* Specific style to avoid the notch */
      /* Apple iPhone XR 1792x828px at 326ppi Media Queries */
      @media only screen
      and (device-width : 414px)
      and (device-height : 896px)
      and (-webkit-device-pixel-ratio : 2) {
        top: 1vw;
        left: 4vw;
      }

      /* Apple iPhone XS 2436x1125px at 458ppi Media Queries */
      @media only screen
      and (device-width : 375px)
      and (device-height : 812px)
      and (-webkit-device-pixel-ratio : 3) {
        top: 1vw;
        left: 4vw;
      }

      /* Apple iPhone XS Max 2688x1242px at 458ppi Media Queries */
      @media only screen
      and (device-width : 414px)
      and (device-height : 896px)
      and (-webkit-device-pixel-ratio : 3) {
        top: 1vw;
        left: 4vw;
      }

      /* Apple iPhone X Media Queries */
      @media only screen
      and (device-width : 375px)
      and (device-height : 812px)
      and (-webkit-device-pixel-ratio : 3) {
        top: 1vw;
        left: 4vw;
      }
    }

    .VoiceOver__content {
      position: fixed;
      top: 6vw;
      left: 6vw;
      width: 30vw;
      padding: 5vw 5vw 1vw 5vw;
      box-shadow: 0 0 10px $medium-grey;

      z-index: 2;
      background: #fff;
      transform: scale(1);
      transform-origin: top left;


      &--help {
        width: 40vw;
        h3 {
          text-transform: uppercase;
          margin-bottom: 0.5em;
          font-weight: 600;
        }
      }

      .VoiceOver__close {
        width: 8%;
        top: 1rem;
        right: 1rem;
      }

      /* Specific style to avoid the notch */
      /* Apple iPhone XR 1792x828px at 326ppi Media Queries */
      @media only screen
      and (device-width : 414px)
      and (device-height : 896px)
      and (-webkit-device-pixel-ratio : 2) {
        top: 5vw;
        left: 8vw;
      }

      /* Apple iPhone XS 2436x1125px at 458ppi Media Queries */
      @media only screen
      and (device-width : 375px)
      and (device-height : 812px)
      and (-webkit-device-pixel-ratio : 3) {
        top: 5vw;
        left: 8vw;
      }

      /* Apple iPhone XS Max 2688x1242px at 458ppi Media Queries */
      @media only screen
      and (device-width : 414px)
      and (device-height : 896px)
      and (-webkit-device-pixel-ratio : 3) {
        top: 5vw;
        left: 8vw;
      }

      /* Apple iPhone X Media Queries */
      @media only screen
      and (device-width : 375px)
      and (device-height : 812px)
      and (-webkit-device-pixel-ratio : 3) {
        top: 5vw;
        left: 8vw;
      }
    }
  }

  &__room {
     -ms-overflow-style: none;
    .VoiceOver__image {
      position: absolute;
      top: 2%;
      left: 1.5%;
      width: 10%;
      z-index: 5;
    }

    .VoiceOver__content {
      position: absolute;
      top: 10%;
      left: 6%;
      width: 86%;
      padding: 7% 7% 3% 7%;
      box-shadow: 0 0 10px $medium-grey;
      overflow: scroll;

      &--help {
        width: 60%;
        h3 {
          text-transform: uppercase;
          margin-bottom: 0.5em;
          font-weight: 600;
        }
      }

      @media (max-aspect-ratio: 1/1) {
        max-height: 65vh;
      }
      @media (min-aspect-ratio: 1/1) {
        max-height: 80vh;
      }

      z-index: 4;
      background: #fff;
      transform: scale(1);
      transform-origin: top left;


      &::-webkit-scrollbar {
        display: none;
      }

      .VoiceOver__close {
        cursor: pointer;
        width: 4%;
        top: 1rem;
        right: 1rem;
      }

      &--subsequent_room {
        padding: 5% 5% 3% 5%;
        width: 50%;
        .VoiceOver__close {
          width: 6%;

          top: 0.5rem;
          right: 0.5rem;

          @media (min-width: $md) {
            top: 1rem;
            right: 1rem;
          }
        }
      }

      &--first_room p {
        /* Apple iPhone XR 1792x828px at 326ppi Media Queries */
        @media only screen
        and (device-width : 414px)
        and (device-height : 896px)
        and (-webkit-device-pixel-ratio : 2) {
          font-size: 100%;
        }

        /* Apple iPhone XS Max 2688x1242px at 458ppi Media Queries */
        @media only screen
        and (device-width : 414px)
        and (device-height : 896px)
        and (-webkit-device-pixel-ratio : 3) {
          font-size: 100%;
        }

        /* Apple iPhone X Media Queries */
        @media only screen
        and (device-width : 375px)
        and (device-height : 812px)
        and (-webkit-device-pixel-ratio : 3) {
          font-size: 70%;
        }

      }
    }

    .VoiceOver__intro {
      top: 12%;
      left: 8%;
      width: 84%;
      padding: 50px 20px 10px 50px;
    }
  }


  .HotspotIcon-example {
    height: $heading-line-height;
    width: $heading-line-height;
    margin-bottom: -0.3em;
  }

  .expand-appear, .expand-enter, .expand-leave.expand-leave-active {
    transform: scale(0);
  }

  .expand-appear.expand-appear-active, .expand-enter.expand-enter-active, .expand-leave {
    transform: scale(1);
    transition: transform 1000ms ease-in;
  }

}
