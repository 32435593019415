.RoomPreview {
  &__Overlay {
    position: absolute;
    overflow: scroll;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1012;
    background: rgba(0,0,0,0.5);
  } 

  &__Container {
    position: absolute;
    display: flex;
    width: 430px;
    height: 130px;
    z-index: 20;
  }

  &__Content {
    width: 160px;
    height: 130px;
    position: absolute;
    left: 5%;
    top: 5%;
    display: flex;
    flex-direction: column;
    font-size: 1.25 * $base-font-size;
    color: #ffffff;

    .Button {
      position: absolute;
      left: 0;
      bottom: 15%;
    }
  }

  &__Image {
    position: absolute;
    right: 2%;
    top: 5%;
    height: 100%;
    img {
      height: 90%;
      width: auto;
    }
  }

  &__Close {
    position: absolute;
    transform: rotate(45deg);
    top: -20%;
    right: -5%;
  }

  &-ambassadors_office { left: 39.5%; top: 53%; }
  &-office             { left: 39.5%; top: 62%; }
  &-reception          { left: 39.5%; top: 75%; }
  &-exterior_front     { left: 31%; top: 75%; }
  &-gatehouse          { left: 23.1%; top: 75%; }
  &-server_room        { left: 55%; top: 53%; }
  &-kitchen            { left: 55%; top: 62%; }
  &-meeting_room       { left: 55%; top: 75%; }
  &-exterior_back      { left: 70%; top: 75%; }
  &-roof               { left: 46.95%; top: 43.5%; }
  &-lift               { left: 46.95%; top: 75%; }
  &-garage             { left: 39.5%; top: 84%; }
  &-basement           { left: 54.4%; top: 84%; }
  &-perimeter          { left: 23%; top: 75%; }
}