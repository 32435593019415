@import "reset-css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);

@import
  "_variables",
  "screens/Embassy",
  "screens/Start",
  "screens/Welcome",
  "components/Button",
  "components/Carousel",
  "components/Content",
  "components/HotspotContent",
  "components/GuideSummary",
  "components/VoiceOver",
  "components/Overlay",
  "components/RoomMini",
  "components/RoomPreview",
  "components/RoomButton",
  "components/RoomsHistoryMap",
  "components/Menu",
  "components/MobileHelpers"
;

html, body, * {
  box-sizing: border-box;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}

html, body {
  font-size: 2 * $base-font-size;
  @media (min-width: $md) {
    font-size: 1.5 * $base-font-size;
  }
  @media (min-width: $lg) {
    font-size: 1.5 * $base-font-size;
  }
  @media (min-width: $xl) {
    font-size: 1.3 * $base-font-size;
  }
  font-family: "Open Sans", sans-serif;
}

.App {
  min-height: 100vh;
  position: relative;
}


.spaced-out-content {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;

  &__buttons {
    justify-content: space-around;
    justify-content: space-evenly;
  }
}

.fade-appear, .fade-appear.fade-appear-active,
.fade-enter, .fade-enter.fade-enter-active,
.fade-leave, .fade-leave.fade-leave-active {
  transition: opacity 500ms ease-in;
}

.fade-enter,
.fade-leave {
  opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
  opacity: 1;
}

