@import "../_variables";

.GuideSummary {
  &__image {
    display: block;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    padding: 10px 10px 0 10px;
    @media (min-width: $lg) {
      padding: 0;
    }
  }

  &__role {
    text-align: center;
    color: $text-color;
    font-size: 90%;
    @media (min-width: $lg) {
      font-size: 100%;
    }
    line-height: $text-line-height;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .Start__pick &--selected &__role,
  .Start__pick &:hover &__role {
    font-weight: bold;
  }

  &__designing_buildings,
  &__designing_features,
  &__constructing,
  &__installing,
  &__sustainability,
  &__projects,
  &__warehouse,
  &__cultural {
    font-weight: 600;
  }

  .Start__pick &--hover {
    transform: scale(1.2);
  }

  &__Current-Guide {
    transform: scale(1.1);
    @media (min-width: $lg) {
      transform: scale(1.2);
    }
  }
}