.RoomMini {
  position: absolute;

  width: 8.08%;
  z-index: 100;

  transition: left 1s, top 1s, width 1s, height 1s;

  img {
    width: 100%;
    z-index: 100;
    position: relative;
    display: block;
  }

  p {
    font-size: 90%;
    @media (min-width: $md) {
      font-size: 100%;
    }
    @media (min-width: $lg) {
      font-size: 120%;
    }
    color: #ffffff;
    margin: 0;
    padding: 0;
  }

  .Button {
    position: absolute;
    bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 0.7 * $button-line-height;
  }

  &__Close {
    position: absolute;
    transform: rotate(45deg);
    top: -25px;
    right: -25px;
    @media (min-width: $md) {
      top: -40px;
      right: -35px;
    }
    z-index: 101;
  }

  &-details {
    position: absolute;
    top: 0;
    width: 270%;
    height: 100%;
    &__text-left {
      right: 0;
      padding-right: 100%;
    }
    &__text-right {
      padding-left: 110%;
    }
  }

  &__background {
    background: $stripe-pattern;
    position: absolute;
    left: 0;
    right: 0;
    top: -1000px;
    bottom: -1000px;
    z-index: 99;

    &__visible {
      opacity: 1;
      transition: opacity 1s;
      top: -1000px;
    }

    &__invisible {
      opacity: 0;
      transition: opacity 1s;
      top: 10000px;
    }
  }

  &-ambassadors_office { left: 48.13%; top: 58.34%; }
  &-kitchen            { left: 63.28%; top: 69.68%; }
  &-server_room        { left: 63.28%; top: 59.34%; }
  &-office             { left: 48.13%; top: 68.5%; }
  &-reception          { left: 48.13%; top: 82.5%; }
  &-exterior_front     { left: 40%; top: 84%; }
  &-gatehouse          { left: 32%; top: 84%; }
  &-meeting_room       { left: 63.28%; top: 82.5%; }
  &-exterior_back      { left: 75%; top: 75%; }
  &-roof               { left: 52.55%; top: 43.5%; }
  &-lift               { left: 52.55%; top: 75%; }
  &-garage             { left: 48.13%; top: 90%; }
  &-perimeter          { left: 20%; top: 84%; }
  &-basement           { left: 63.28%; top: 90%; }
  &-kitchen,
  &-basement,
  &-exterior_back {
    p {
      font-size: 70%;
      @media (min-width: $md) {
        font-size: 100%;
      }
    }

    button.Button--white {
      line-height: 0.5 * $button-line-height;
      font-size: 0.6 * $button-font-size;
      @media (min-width: $md) {
        line-height: 0.7 * $button-line-height;
        font-size: 0.7 * $button-font-size;
      }
    }
  }
}