@import "../_variables";

.Button {
  border: none;
  border-radius: 0.7 * $button-border-radius;
  line-height: 0.7 * $button-line-height;
  font-size: 0.7 * $button-font-size;

  @media (min-width: $lg) {
    border-radius: $button-border-radius;
    padding: 0 $button-padding-horizontal;
    line-height: $button-line-height;
    font-size: $button-font-size;
  }
  color: $button-color;
  background: $button-background;
  transition: all 100ms;
  cursor: pointer;

  a, a:visited, a:active, a:focus {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    background: darken($button-background, 5%);
  }

  &--green {
    background: $button-green-background;

    &:hover {
      background: darken($button-green-background, 5%);
    }
  }
  &--white {
    background: $button-white-background;

    &:hover {
      background: darken($button-white-background, 5%);
    }
  }
}
