.MobileHelpers {
  position: fixed;
  top: 0;
  bottom: -100px;
  left: 0;
  right: 0;
  background-color: $sky-background;
  z-index: 10000;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;

  &__message {
    text-align: center;
    font-size: 200% !important;
  }
}
